import _reactDomProductionMin from "./cjs/react-dom.production.min.js";

function checkDCE() {
  /* global __REACT_DEVTOOLS_GLOBAL_HOOK__ */
  if (typeof __REACT_DEVTOOLS_GLOBAL_HOOK__ === "undefined" || typeof __REACT_DEVTOOLS_GLOBAL_HOOK__.checkDCE !== "function") {
    return;
  }

  try {
    // Verify that the code above has been dead code eliminated (DCE'd).
    __REACT_DEVTOOLS_GLOBAL_HOOK__.checkDCE(checkDCE);
  } catch (err) {
    // DevTools shouldn't crash React, no matter what.
    // We should still report in case we break this code.
    console.error(err);
  }
}

{
  // DCE check should happen before ReactDOM bundle executes so that
  // DevTools can report bad minification during injection.
  checkDCE();
  var exports = _reactDomProductionMin;
}
export default exports;
export const __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED = exports.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED,
      createPortal = exports.createPortal,
      findDOMNode = exports.findDOMNode,
      flushSync = exports.flushSync,
      hydrate = exports.hydrate,
      render = exports.render,
      unmountComponentAtNode = exports.unmountComponentAtNode,
      unstable_batchedUpdates = exports.unstable_batchedUpdates,
      unstable_createPortal = exports.unstable_createPortal,
      unstable_renderSubtreeIntoContainer = exports.unstable_renderSubtreeIntoContainer,
      version = exports.version;